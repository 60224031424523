import { createStore } from 'vuex'
import { ethers } from 'ethers'
import ContractArtifact from "../utils/martians.json";
import axios from 'axios'

export default createStore({
  state: {
    account: null,
    error: null,
    total: null,
    preSaleStatus: null,
    contract_address: '0xf869F888024E6095E205F343756b4fC7Ed31542C'
  },
  mutations: {
    setAccount (state, account) {
      state.account = account
    },
    setError (state, error) {
      state.error = error
    },
  },
  getters: {
    account: (state) => state.account,
    preSaleStatus: (state) => state.preSaleStatus,
    error: (state) => state.error,
    total: (state) => state.total
  },
  actions: {
    async connect ({ commit, dispatch }, connect) {
      console.log("Trying to connect...")
      try {
        const { ethereum } = window
        console.log("Trying to connect Metamask.")
        if (!ethereum) {
          commit('setError', 'Metamask not installed!')
          return
        }
        if (!(await dispatch('checkIfConnected')) && connect) {
          await dispatch('requestAccess')
        }
        await dispatch('checkNetwork')
      } catch (error) {
        console.log(error)
        commit('setError', 'Account request refused.')
      }
    },
    async checkNetwork ({ commit, dispatch }) {
      const chainId = await ethereum.request({ method: 'eth_chainId' })
      const rinkebyChainId = '0x4'
      if (chainId !== rinkebyChainId) {
        if (!(await dispatch('switchNetwork'))) {
          commit(
            'setError',
            'You are not connected to the Rinkeby Test Network!'
          )
        }
      }
    },
    async switchNetwork () {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x4' }]
        })
        return 1
      } catch (switchError) {
        return 0
      }
    },
    async checkIfConnected ({ commit }) {
      const { ethereum } = window
      const accounts = await ethereum.request({ method: 'eth_accounts' })
      if (accounts.length !== 0) {
        commit('setAccount', accounts[0])
        return 1
      } else {
        return 0
      }
    },
    async requestAccess ({ commit }) {
      const { ethereum } = window
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts'
      })
      commit('setAccount', accounts[0])
    },
    async getContract({ state }) {
      try {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          state.contract_address,
          ContractArtifact.abi,
          signer
        );
        console.log(connectedContract);
        return connectedContract;
      } catch (error) {
        console.log(error);
        console.log("connected contract not found");
        return null;
      }
    },

    async getMountedContract({ state }) {
      try {
        let provider = ethers.getDefaultProvider("rinkeby");
        const mountedContract = new ethers.Contract(state.contract_address, ContractArtifact.abi, provider);
        return mountedContract;
      } catch(error) {
        console.log(error);
      }
    },

    async getTotalSupply({ state, commit, dispatch }) {
      try {
        //const connectedContract = await dispatch("getContract");
        let provider = ethers.getDefaultProvider("rinkeby");
        let contract = new ethers.Contract(state.contract_address, ContractArtifact.abi, provider);

        let total = await contract.totalSupply();
        console.log(Number(total));
        state.total = Number(total);
      } catch (error) {
        console.log(error)
      }
    },
    async checkPreSaleStatus({ state, commit, dispatch }) {
      try {
        const contract = await dispatch('getMountedContract')

        let status = await contract.PreSaleIsActive();
        state.preSaleStatus = status;
      } catch (error) {
        console.log(error)
      }
    },
    async mintToken({ state, commit, dispatch }, obj) {
      console.log('Mint new token')
      try {
        const connectedContract = await dispatch("getContract");
        let price = await connectedContract.SalePrice();
        price = ethers.utils.formatEther(price)
        price = price * obj.amount
        //console.log(price, "sd", obj.amount)
        const mintTxn = await connectedContract.mintMartian(obj.amount, { gasLimit: 1000000, value: ethers.utils.parseEther(String(price))});
        await mintTxn.wait();
      } catch (error) {
        console.log(error);
      }
    },
    async preMintToken({ state, commit, dispatch }, obj) {
      console.log('Mint new token')
      try {
        const connectedContract = await dispatch("getContract");
        let price = await connectedContract.PreSalePrice();
        price = ethers.utils.formatEther(price)
        price = price * obj.amount
        //console.log(price, "sd", obj.amount)
        const mintTxn = await connectedContract.mintMartianPresale(obj.amount, { gasLimit: 300000, value: ethers.utils.parseEther(String(price))});
        await mintTxn.wait();
      } catch (error) {
        console.log(error);
      }
    }
  },
  modules: {
  }
})