<template>
  <body class="mint-page">
    <navbar-component></navbar-component>
    <router-view/>
  </body>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue'

export default {
  components: { NavbarComponent }
}
</script>

<style lang="scss">

</style>
