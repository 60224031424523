<template>
  <div style="display:block;" class="pop-up" v-show="pop_up">
    <div data-w-id="f95efada-977e-fbd1-b217-7e4185875a87" class="close_area"></div>
    <div class="pop-upcontent">
      <div data-w-id="0a0414c7-066d-d392-d262-7e656d643f39" class="close" @click="pop_up=false"><img src="images/close.svg" loading="lazy" alt="" class="close_icon"></div>
      <div class="popuptitle">
        <h1 class="heading-13">Choose your wallet</h1>
      </div>
      <div class="light popup"></div>
      <div id="wallet_connect" class="wc"><img src="images/Group-185.svg" loading="lazy" alt="" class="wc_pop"></div>
      <div id="metamask" class="met" @click="connect"><img src="images/Group-179.svg" loading="lazy" alt="" class="metpop"></div>
    </div>
  </div>
  <div class="minting-section wf-section">
    <div class="minting-wrapper">
      <div class="minting-left">
        <div class="minting-header">
          <h2 class="heading-2 minging-page">MINTING</h2>
          <div class="subtitle left"><span class="color-text">{{ supply }}</span>/11.111</div>
        </div>
        <div class="mint-text-and-btn">
          <div class="minting-info">
            <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="minting-info-tab w-tabs">
              <div class="minting-tab-buttons w-tab-menu">
                <a data-w-tab="Tab 1" class="minting-tab-btn left w-inline-block w-tab-link" :class="{ current : tab == 1 }" @click="tab = 1">
                  <div>Minting Info</div>
                </a>
                <a data-w-tab="Tab 2" class="minting-tab-btn right w-inline-block w-tab-link" :class="{ current : tab == 2 }" @click="tab = 2">
                  <div>How To Mint</div>
                </a>
              </div>
              <div class="w-tab-content">
                <div data-w-tab="Tab 1" class="w-tab-pane" :class="{ wtabactive : tab == 1 }">
                  <div class="div-block-29 minting-page">
                    <div id="w-node-_2f44f6f9-389f-79db-5c46-349ef3128fad-70376cc6" class="list-conent minting-page"><img width="90" height="" src="images/Icon-05.png" loading="lazy" alt="" class="image-11 minting-page">
                      <div class="list-text total-supply">
                        <h4 class="minting-info-title">Total supply</h4>
                        <p class="paragraph-2 small">11,111<br>NFTs</p>
                      </div>
                    </div>
                    <div id="w-node-_2f44f6f9-389f-79db-5c46-349ef3128fb6-70376cc6" class="list-conent minting-page"><img width="90" height="" src="images/Icon-06.png" loading="lazy" alt="" class="image-11 minting-page">
                      <div class="list-text mint-per-wallet">
                        <h4 class="minting-info-title">Mint per Wallet</h4>
                        <p class="paragraph-2 small">3 NFTs <br>Max</p>
                      </div>
                    </div>
                    <div id="w-node-_2f44f6f9-389f-79db-5c46-349ef3128fbf-70376cc6" class="list-conent minting-page"><img width="90" height="" src="images/Icon-07.png" loading="lazy" alt="" class="image-11 minting-page">
                      <div class="list-text presale">
                        <h4 class="minting-info-title">Presale details</h4>
                        <p class="paragraph-2 small">Date: 1 June 2022<br>Price: 0,12 ETH</p>
                      </div>
                    </div>
                    <div id="w-node-_2f44f6f9-389f-79db-5c46-349ef3128fc8-70376cc6" class="list-conent minting-page"><img width="90" height="" src="images/Icon-08.png" loading="lazy" alt="" class="image-11 minting-page">
                      <div class="list-text public">
                        <h4 class="minting-info-title">Public sale details</h4>
                        <p class="paragraph-2 small">Date: 3 June 2022<br>Price: 0,18 ETH</p>
                      </div>
                    </div>
                    <div id="w-node-_2f44f6f9-389f-79db-5c46-349ef3128fd1-70376cc6" class="list-conent minting-page">
                      <div class="list-text minting-page">
                        <p class="paragraph-2 topline minting-page"><span class="link-3">Whitelist Information<br>‍</span><br>Get guaranteed access to the mint for the “early bird” price. <br>‍<br>Join our <a href="https://discord.gg/4bfvEMKSDE" target="_blank" class="link-3"><span class="text-span-12">Discord server</span></a> and follow the eligibility requirements. <br>‍<br>Stay engaged, invite people, participate in giveaways, and get in early!</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-w-tab="Tab 2" class="w-tab-pane" :class="{ wtabactive : tab == 2 }">
                  <div class="tab-right-wrapper">
                    <p class="paragraph-2 topline minting-page"><span class="margin-15">1. Connect wallet</span><br><span class="align-text">Click Connect, then choose your wallet provider and confirm connection in your wallet.</span><br>‍<br><br><span class="margin-15">2. Choose Mint Quantity</span><br><span class="align-text">The maximum mint quantity is 3.</span><br>‍<br><br><span class="margin-15">3. Check your wallet</span><br><span class="align-text">Make sure you have enough ETH in your wallet to cover mint cost and max. gas fees</span><br>‍<br><br><span class="margin-15">4. Confirm transaction</span><br><span class="align-text">Confirm the transaction and wait for the network to process It.</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="minting-buttons-wrapper">
            <div class="minting-buttons">
              <div class="mint-btn-wrapper">
                <a style="cursor: pointer;" id="main_btn" data-w-id="82e09dfe-7c67-43d5-c905-5312ca6eaf8a" class="main-button minting-page w-inline-block" @click="mint">
                  <div class="main-button-content">
                    <div class="btn_stroke_wrapper">
                      <div style="opacity:1" class="btn_dot"></div>
                      <div class="btn_text">Mint</div>
                      <div style="opacity:1" class="btn_dot"></div>
                    </div>
                  </div>
                  <div id="video" class="main-button-elements">
                    <div class="btn_orange_element l"></div>
                    <div class="btn_orange_element riverse"></div>
                  </div>
                </a>
                <div v-show="wrong_amount">Amount of NFT should be from 1 to 3. Please, change the amount.</div>
                <div class="contract-sale on" v-show="account">
                  <!-- <div class="wallet-connected-icon w-embed"><svg width="20" height="10" viewbox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 0H12C11.45 0 11 0.45 11 1C11 1.55 11.45 2 12 2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H12C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM6 5C6 5.55 6.45 6 7 6H13C13.55 6 14 5.55 14 5C14 4.45 13.55 4 13 4H7C6.45 4 6 4.45 6 5ZM8 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H8C8.55 10 9 9.55 9 9C9 8.45 8.55 8 8 8Z" fill="url(#paint0_linear_740_590)"></path>
                      <defs>
                        <lineargradient id="paint0_linear_740_590" x1="10" y1="0" x2="10" y2="10" gradientunits="userSpaceOnUse">
                          <stop stop-color="#D8D5DC"></stop>
                          <stop offset="1" stop-color="#C3B5C5"></stop>
                        </lineargradient>
                      </defs>
                    </svg></div> -->
                  <div class="wallet-connected-text">Wallet Connected</div>
                </div>
                <div class="contract-sale off" v-show="!account">
                  <!-- <div class="wallet-connected-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.39 9.06499L14 10.675V9.06499H12.39ZM15 5.06499H11V6.96499H15C16.71 6.96499 18.1 8.35499 18.1 10.065C18.1 11.335 17.33 12.435 16.23 12.905L17.63 14.305C19.05 13.425 20 11.855 20 10.065C20 7.30499 17.76 5.06499 15 5.06499ZM0 2.33499L3.11 5.44499C1.29 6.18499 0 7.97499 0 10.065C0 12.825 2.24 15.065 5 15.065H9V13.165H5C3.29 13.165 1.9 11.775 1.9 10.065C1.9 8.47499 3.11 7.16499 4.66 6.99499L6.73 9.06499H6V11.065H8.73L11 13.335V15.065H12.73L16.74 19.075L18.15 17.665L1.41 0.924988L0 2.33499Z" fill="url(#paint0_linear_749_302)"></path>
                      <defs>
                        <lineargradient id="paint0_linear_749_302" x1="10" y1="4.81427" x2="10" y2="15.6178" gradientunits="userSpaceOnUse">
                          <stop stop-color="#F0908B"></stop>
                          <stop offset="1" stop-color="#EF7971"></stop>
                        </lineargradient>
                      </defs>
                    </svg></div> -->
                  <div class="wallet-disconnected-text">Wallet Disconnected</div>
                </div>
              </div>
              <div class="minting-cart-counter">
                <a style="cursor: pointer;" class="cart-inc-btn" @click="add_mint">+</a>
                <div class="cart-number-text">{{mint_count}}</div>
                <a style="cursor: pointer;" class="cart-dec-btn" @click="minus_mint">-</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="minting-right">
        <div class="w-embed">
          <div data-poster-url="https://uploads-ssl.webflow.com/624b718285f681678229be45/625e2abe1b62a319e416901a_mintingpage_final-poster-00001.jpg" data-video-urls="https://res.cloudinary.com/ahmadeyamin/video/upload/v1650365819/mintingpage_final_ugzibw.mp4" data-autoplay="true" data-loop="true" data-wf-ignore="true" class="minting-video w-background-video w-background-video-atom">
            <video autoplay="" loop="" style="background-image:url(&quot;https://uploads-ssl.webflow.com/624b718285f681678229be45/625e2abe1b62a319e416901a_mintingpage_final-poster-00001.jpg&quot;)" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover">
              <source src="https://res.cloudinary.com/ahmadeyamin/video/upload/v1650365819/mintingpage_final_ugzibw.mp4" data-wf-ignore="true">
              <source src="https://res.cloudinary.com/ahmadeyamin/video/upload/v1650365819/mintingpage_final_ugzibw.mp4" data-wf-ignore="true">
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer wf-section">
    <div class="footer-container align-center w-container">
      <a href="index.html" class="w-inline-block"><img src="images/Group-185.png" loading="lazy" width="122" alt=""></a>
      <div class="footer-links">
        <a href="#" class="link-2 somelink">Some title link</a>
        <a href="#" class="link-2 support">Support center</a>
        <a href="mailto:mailto:info@martiancolony.io" class="link-2">Contact the Martians</a>
        <a href="#" class="link-2 terms">Team &amp; conditions</a>
        <a href="#" class="link-2 somlink2">Some title link</a>
      </div>
      <a href="#" class="button w-inline-block">
        <div class="btn_text">Mint</div><img src="https://uploads-ssl.webflow.com/62470ebc4eb9c3173f785423/624716e173d1547a965933de_cart_icon.svg" loading="lazy" width="20" height="20" alt="" class="icon btn">
      </a>
      <div class="social-media">
        <div class="social-media-icons"><img src="https://uploads-ssl.webflow.com/62470ebc4eb9c3173f785423/624716f7ee19e1a316c3304b_opensea-seeklogo.com%201.svg" loading="lazy" width="24" height="24" alt="" class="icon corablik">
          <a id="martian_inst" href="https://www.instagram.com/martiancolonynft/" target="_blank" class="instagram w-inline-block">
            <div class="icon w-embed"><svg role="img" viewbox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <title>Instagram</title>
                <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"></path>
              </svg></div>
          </a>
          <a id="martian_twit" href="https://twitter.com/martian_colony" target="_blank" class="twitter w-inline-block">
            <div class="icon w-embed"><svg role="img" viewbox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <title>Twitter</title>
                <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"></path>
              </svg></div>
          </a>
          <a id="martian_disc" href="https://discord.gg/4bfvEMKSDE" target="_blank" class="discord w-inline-block">
            <div class="icon w-embed"><svg role="img" fill="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <title>Discord</title>
                <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"></path>
              </svg></div>
          </a>
          <a id="martian_disc" href="https://raritysniper.com/nft-drops-calendar" target="_blank" class="rarity_snyper w-inline-block">
            <div class="icon _4 f w-embed"><svg role="img" fill="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <title>Discord</title>
                <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"></path>
              </svg></div><img src="images/rarity-sniper-logosvg.svg" loading="lazy" width="24" height="24" alt="" class="rarity_snyper img">
          </a>
        </div>
      </div>
      <div class="text term">Privacy policy &amp; terms and conditions</div>
      <a href="final-page.html" target="_blank" class="termsconditions">Terms &amp; Conditions</a>
    </div>
  </div>
</template>

<script>
import whitelistCollection from '../firebase-config.js'
import { getDocs, addDoc, doc, deleteDoc } from 'firebase/firestore/lite';

export default {
  data() {
    return {
      array: [],
      addresses: [],
      length: null,
      mint_count: 0,
      pop_up: false,
      tab: 1,
      wrong_amount: false
    }
  },
  methods: {
    async connect () {
      await this.$store.dispatch('connect', true)
      this.pop_up = false
      
    },
    async mint() {
      if (!this.account) {
        this.pop_up = true
      } else {
        if (this.mint_count <= 0 || this.mint_count > 3) {
          this.wrong_amount = true
        } else {
          let addr = this.account.toString()
          console.log("Addr: ", addr)
          this.addresses.filter(item => {
            this.array.push(item.address.toLowerCase())
          });
          if (this.array.includes(addr) && this.preSaleStatus) {
            await this.$store.dispatch("preMintToken", { amount: this.mint_count });
          } else {
            await this.$store.dispatch("mintToken", { amount: this.mint_count });
          }
          this.array.splice(0)
        }
      }
    },

    add_mint() {
      this.mint_count += 1
      if (this.mint_count > 2) {
        this.mint_count = 3
      }
      
    },
    minus_mint() {
      this.mint_count = this.mint_count - 1
      if (this.mint_count < 1) {
        this.mint_count = 0
      }
    },
    async getWhitelist() {
      let whitelist = await getDocs(whitelistCollection);
      whitelist.forEach(address => {
        let addressData = address.data()
        addressData.id = address.id
        this.addresses.push(addressData)
      })
      this.length = this.addresses.length
    },
  },
  created() {
    this.getWhitelist()
  },
	async mounted() {
    //await this.$store.dispatch("connect", false);
    await this.$store.dispatch("getTotalSupply");
    await this.$store.dispatch("checkPreSaleStatus");
  },
  computed: {
    account() {
      return this.$store.getters.account;
    },
    supply() {
      return this.$store.getters.total;
    },
    preSaleStatus() {
      return this.$store.getters.preSaleStatus;
    },
  }
}
</script>
